import { Container } from '@mui/system';

import Bot from '../../images/icons/bot.png';
import Person from '../../images/icons/person.png';

const ContactHero = ({ contact, title }) => {
  return (
    <div className="contact-hero__wrapper">
      <div className="contact-hero__background"></div>
      <Container>
        <div className="contact-hero__left">
          <h1 className="contact-title">{title}</h1>
          <div className="contact-options__wrapper">
            {contact.map((cont) => (
              <div className="contact-options__box">
                <div></div>
                <div>
                  <img
                    src={cont.image.url}
                    alt="contact method"
                    style={{ width: 96, height: 96 }}
                  ></img>
                </div>
                <h2>{cont.title}</h2>
                <p>{cont.description.data.description}</p>
                {cont.buttonText && (
                  <div>
                    <a href={cont.buttonLink} className="button primary-button">
                      {cont.buttonText}
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactHero;
